import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  const lnw_locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.js$/i);
  lnw_locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    // console.log("matched",matched);
    // console.log("key", key);
    // console.log("lnw_locales(key)", lnw_locales(key));
    if (matched && matched.length > 1) {
      const lnw_locale = matched[1]
      messages[lnw_locale] = lnw_locales(key)
    }
  })
  //console.log(messages);
  return messages
}

const localStorageKey = "locale";

const dateTimeFormats = {
  'en': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  'th': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    }
  }
}

const i18n = new VueI18n({
  locale: localStorage.getItem(localStorageKey) || (process.env.VUE_APP_I18N_LOCALE || 'en'),
  silentTranslationWarn: true,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || ['th', 'en'],
  messages: loadLocaleMessages(),
  dateTimeFormats
});

export default i18n;

export const setLocale = (locale) => {
  localStorage.setItem(localStorageKey, locale);
  i18n.locale = locale;
};