import * as types from "./mutation-types.js";
import { LNWPAY_CALLBACK_STATES_KEY, setItem } from "@/libraries/OrderLocalStorage";

const config_lnwpay = require('@/config/lnwpay.json');

export default {
  SET_LOGIN_DATA: (state, payload) => {
    state.buyerInfo.email = payload.email;
    state.buyerInfo.mobileNumber = payload.mobileNumber;
    state.buyerInfo.profilePicture = payload.profilePicture;
    state.buyerInfo.isLogin = payload.isLogin;

    state.form.buyerInfoConfirmed = true;
    state.form.buyerInfoAlreadyLogin = true;
  },
  setOrderKey: (state, {order_id, web_name, secret, type}) => {
    state.order_id = order_id;
    state.web_name = web_name;
    state.secret = secret;
    state.type = type; // cart | order
  },
  SET_WEB_NAME: (state, web_name) => {
    state.web_name = web_name
  },
  SET_IS_X: (state, is_x) => {
    state.is_x = is_x;
  },
  setOrderData: (state, {orderData, form_email, form_mobile, existing_owner, form_selectedReceiptContactId}) => {
    state.existingOrderOwner = existing_owner;
    state.orderData = orderData;
    if (orderData) {
      state.form.shipping_type_id = orderData.shipping_type_id;
      state.form.smsCheck = !!(orderData.receive_sms_flag);
      state.form.adsCheck = !!(orderData.allow_ads_flag);
      state.form.lineNotifyCheck = !!(orderData.allow_line_notify_flag);
      if (orderData.receiver_id) {
        state.form.shippingMethodShippingAddressId = orderData.receiver_id;
        // noinspection EqualityComparisonWithCoercionJS
        state.form.shippingMethodShippingDealerAddress = (orderData.receiver && orderData.receiver.contact_key == 'dealer_receiver');
      }
      else {
        // @todo ดักด้วยเงื่อนไขไหนดี ที่ให้รู้ว่ามีข้อมูล receiver ทดไว้ที่ cart แล้ว
        if (orderData.receiver?.address) {
          state.form.shippingMethodShippingAddressId = -2;
          orderData.receiver_id = -2;
          orderData.receiver.contact_id = -2;
        }
        else
          state.form.shippingMethodShippingAddressId = null;
      }

      // default state of paymentMethod
      if (state.form.paymentMethodInputValue === null) {
        const paymentMethodInputValue = {
          selectedMethod:null,
        };
        if (orderData.payment_provider) {
          for (let paymentMethod of orderData.payment_methods) {
            // ในกรณีที่ payment_provider(key) มันไปเท่ากับ paymentMethod.key เช่น credit_card
            if (orderData.payment_provider === paymentMethod.key) {
              paymentMethodInputValue.selectedMethod = paymentMethod.key;
              paymentMethodInputValue[paymentMethod.key] = {
                provider_key: paymentMethod.key,
              };
            }
            else {
              if (Array.isArray(paymentMethod.providers)) {
                for (let provider of paymentMethod.providers) {
                  let o_key = provider.key;
                  o_key = o_key.replace("billpayment", "bank_transfer");
                  if (o_key === orderData.payment_provider) {
                    paymentMethodInputValue.selectedMethod = paymentMethod.key;
                    paymentMethodInputValue[paymentMethod.key] = {
                      provider_key: provider.key,
                    };
                  }
                }
              }
            }
          }
          state.form.paymentMethodInputValue = Object.assign({}, paymentMethodInputValue);
        }
      }

    }

    state.form.buyerInfoEmail = form_email;
    state.form.buyerInfoMobileNumber = form_mobile;
    if (typeof form_selectedReceiptContactId !== 'undefined') {
      state.form.selectedReceiptContactId = form_selectedReceiptContactId;
    }

  },
  setPaymentMethodsOnlyBankTransfer: (state, paymentMethods) => {
    //ไว้ใช้สำหรับแจ้งชำระเงินที่ module myOrder
    state.orderData = {};
    state.orderData.payment_methods = new Array();
    if(paymentMethods.is_lnwpay_full){
      state.orderData.payment_methods.push(paymentMethods.payment_config_lnwpay.bank_transfer);
    }else if(paymentMethods.is_lnwpay_lite){
      state.orderData.payment_methods.push(paymentMethods.payment_config_lnwpay_lite.bank_transfer);
    }else {
      state.orderData.payment_methods.push(paymentMethods.payment_config_lnwshop.bank_transfer);
    }
    //เอามาเฉพาะ bank_transfer
    if(state.orderData.payment_methods[0].providers){
      state.orderData.payment_methods[0].providers = Object.values(state.orderData.payment_methods[0].providers);
    }
  },
  setShopData: (state, shopData) => {
    state.shopData = shopData;
  },
  setMallData: (state, mallData) => state.mallData = mallData,
  setForm: (state, payload) => {
    if (payload.hasOwnProperty('key') && payload.hasOwnProperty('value')) {
      state.form[payload.key] = payload.value;
    }
    else {
      for (let key in payload) {
        if (payload.hasOwnProperty(key)) {
          if (typeof payload[key] === 'object')
            state.form[key] = Object.assign({}, payload[key]);
          else
            state.form[key] = payload[key];
        }
      }
    }
  },

  setStepMetas: (state, payload) => {
    state.stepMetas[payload.stepNumber] = payload.stepMeta;
  },
  setStepMetasOpen: (state, payload) => {
    for (let stepNumber in payload) {
      state.stepMetas[stepNumber].open = payload[stepNumber];
    }
  },
  setStepMetaKeyValue: (state, payload) => state.stepMetas[payload.stepNumber][payload.key] = payload.value,

  setFacebookCheckboxPluginRenderedTime: (state, time) => state.facebook_checkbox_plugin_checkbox_rendered_time = time,
  setFacebookCheckboxPluginCheckboxState: (state, checkboxState) => state.facebook_checkbox_plugin_checkbox_state = checkboxState,

  [types.SET_IS_SHOW_OVERLAY]: (state, flag) => state.isShowOverlay = flag,
  [types.SET_IS_SHOW_OVERLAY_PAYMENT]: (state, flag) => state.isShowOverlayPayment = flag,
  [types.SET_IS_AJAX_LOADING]: (state, flag) => state.isAjaxLoading = flag,
  [types.SET_IS_AJAX_RESPONSE]: (state, flag) => state.isAjaxResponse = flag,
  [types.SET_IS_AJAX_RESPONSE_MESSAGE]: (state, msg) => state.isAjaxResponseMessage = msg,
  [types.SET_IS_COPIED]: (state, flag) => state.isCopied = flag,
  [types.SET_IS_LOGIN_REQUIRED]: (state, flag) => state.isLoginRequired = flag,
  [types.SET_ENABLE_SET_IS_AJAX_RESPONSE]: (state, flag) => state.enableSetIsAjaxResponse = flag,
  [types.SET_INFORM_DIALOG]: (state, flag) => state.informDialog = flag,
  [types.SET_KPLUS_DIALOG]: (state, {flag,url}) => {
    state.kplusPaymentUrl = url;
    state.kplusDialogFlag = flag;
  },
  [types.SET_LNWPAY_CALLBACK_STATES]: (state, states) => {
    setItem(LNWPAY_CALLBACK_STATES_KEY, JSON.stringify(states), config_lnwpay.sync_order_status_timeout_seconds);
    state.lnwpayCallbackStates = states;
    state.lnwpayCallbackStatesJustEntered = true;
  },
};