import Axios from "@/libraries/Axios";
import ApiUrl from "../../libraries/ApiUrl";
import { fbq } from "@/packages/lnw-vue-pixel/install.js";
import store from "@/store";

const SET_SEARCH_ADDRESSES_RESULT = 'SET_SEARCH_ADDRESSES_RESULT';
const CLEAR_PAGINATED_METADATA = 'CLEAR_PAGINATED_METADATA';

const valid_address_type = type => ['receiverAddresses', 'taxAddresses', 'dealerReceiverAddresses', 'senderAddresses'].includes(type);
const default_paginated_limit = 10;

const SET_CALLED = 'SET_CALLED';
const SET_LINE_NOTIFY_TOKENS = 'SET_LINE_NOTIFY_TOKENS';

const state = {
    access_token: null,

    // ไว้เก็บว่าเคยดึงข้อมูลอะไรมาบ้างแล้ว จะได้ไม่ต้องดึงซ้ำอีก
    called: {
        fetchLineTokens: false,
    },

    paginated_metadata: {
        /**
         * ถ้าจะเพิ่ม properties ตรงนี้ ให้ไปเพิ่มที่ mutations.CLEAR_PAGINATED_METADATA ด้วย
         */
        receiverAddresses: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: false, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        taxAddresses: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: false, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        dealerReceiverAddresses: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: false, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        senderAddresses: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: false, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
    },

    userData: null,
    line_notify_tokens: null,
    accountExists: {
        by: null,
        name: null,
        avatar: null,
    }
}
const getters = {
    axios_headers: (state) => {
        if (state.access_token) {
            return {
                'X-ACCESS-TOKEN': state.access_token,
            }
        } else {
            return null
        }
    },
    isLogin: (state) => !!state.userData && !!state.access_token,
    loggedInEmail: (state) => state.userData ? state.userData.email : false,
    loggedInUserId: (state) => state.userData ? state.userData.user_id : null,
    userData: (state) => state.userData,
    orderDataReceiverId: (state, getters, rootState, rootGetters) => {
        return rootGetters['order/order'].receiver_id;
    },
    orderDataSenderId: (state, getters, rootState, rootGetters) => {
        return rootGetters['order/order'].dealer_id;
    },
    orderDataReceiverKey: (state, getters, rootState, rootGetters) => {
        return rootGetters['order/order'].receiver.contact_key;
    },
    receiverAddressNotExists: (state, getters) => {
        if (!getters.orderDataReceiverId || (!['receiver','guest_receiver'].includes(getters.orderDataReceiverKey)))
            return false;

        if (Object.keys(state.paginated_metadata.receiverAddresses.items).length === 0)
            return true;

        return typeof state.paginated_metadata.receiverAddresses.items[getters.orderDataReceiverId] === 'undefined';
    },
    dealerReceiverAddressNotExists: (state, getters) => {
        if (!getters.orderDataReceiverId || getters.orderDataReceiverKey != 'dealer_receiver')
            return false;

        if (Object.keys(state.paginated_metadata.dealerReceiverAddresses.items).length === 0)
            return true;

        return typeof state.paginated_metadata.dealerReceiverAddresses.items[getters.orderDataReceiverId] === 'undefined';
    },
    senderAddressNotExists: (state, getters) => {
        if (!getters.orderDataSenderId)
            return false;

        if (Object.keys(state.paginated_metadata.senderAddresses.items).length === 0)
            return true;

        return typeof state.paginated_metadata.senderAddresses.items[getters.orderDataSenderId] === 'undefined';
    },
    use_this_sortedReceiverAddresses: (state, getters) => {
        if (getters.receiverAddressNotExists) {
            let sortedReceiverAddresses = [...state.paginated_metadata.receiverAddresses.sortedItems];
            sortedReceiverAddresses.unshift(getters.orderDataReceiverId);
            return sortedReceiverAddresses;
        }
        else {
            return state.paginated_metadata.receiverAddresses.sortedItems;
        }
    },
    use_this_sortedDealerReceiverAddresses: (state, getters) => {
        if (getters.dealerReceiverAddressNotExists) {
            let sortedDealerReceiverAddresses = [...state.paginated_metadata.dealerReceiverAddresses.sortedItems];
            sortedDealerReceiverAddresses.unshift(getters.orderDataReceiverId);
            return sortedDealerReceiverAddresses;
        }
        else {
            return state.paginated_metadata.dealerReceiverAddresses.sortedItems;
        }
    },
    use_this_sortedSenderAddresses: (state, getters) => {
        if (getters.senderAddressNotExists) {
            let sortedSenderAddresses = [...state.paginated_metadata.senderAddresses.sortedItems];
            sortedSenderAddresses.unshift(getters.orderDataSenderId);
            return sortedSenderAddresses;
        }
        else {
            return state.paginated_metadata.senderAddresses.sortedItems;
        }
    },
    // สำหรับเพิ่ม receiverAddress หลอก สำหรับ user ที่ไม่ได้เป็นเจ้าของ order.receiver
    use_this_receiverAddresses: (state, getters, rootState, rootGetters) => {
        if (getters.receiverAddressNotExists) {

            let receiverAddresses = Object.assign({}, state.paginated_metadata.receiverAddresses.items);

            receiverAddresses[getters.orderDataReceiverId] = Object.assign({}, rootGetters['order/order'].receiver);
            receiverAddresses[getters.orderDataReceiverId].id = getters.orderDataReceiverId;
            receiverAddresses[getters.orderDataReceiverId].dummy = true;

            return receiverAddresses;
        }
        else {
            return state.paginated_metadata.receiverAddresses.items;
        }
    },
    // สำหรับเพิ่ม dealerReceiverAddress หลอก สำหรับ user ที่ไม่ได้เป็นเจ้าของ order.receiver
    use_this_dealerReceiverAddresses: (state, getters, rootState, rootGetters) => {
        if (getters.dealerReceiverAddressNotExists) {
            let dealerReceiverAddresses = Object.assign({}, state.paginated_metadata.dealerReceiverAddresses.items);

            dealerReceiverAddresses[getters.orderDataReceiverId] = Object.assign({}, rootGetters['order/order'].receiver);
            dealerReceiverAddresses[getters.orderDataReceiverId].id = getters.orderDataReceiverId;
            dealerReceiverAddresses[getters.orderDataReceiverId].dummy = true;

            return dealerReceiverAddresses;
        }
        else {
            return state.paginated_metadata.dealerReceiverAddresses.items;
        }
    },
    // สำหรับเพิ่ม senderReceiverAddress หลอก สำหรับ user ที่ไม่ได้เป็นเจ้าของ order.dealer
    use_this_senderAddresses: (state, getters, rootState, rootGetters)  => {
        if (getters.senderAddressNotExists) {
            let senderAddresses = Object.assign({}, state.paginated_metadata.senderAddresses.items);

            senderAddresses[getters.orderDataSenderId] = Object.assign({}, rootGetters['order/order'].dealer);
            senderAddresses[getters.orderDataSenderId].id = getters.orderDataReceiverId;
            senderAddresses[getters.orderDataSenderId].dummy = true;

            return senderAddresses;
        }
        else {
            return state.paginated_metadata.senderAddresses.items;
        }
    },
    // เวลาจะเอาไปแสดง list ตัวเลือก ให้ดึงจากตรงนี้แทน เพราะจะเอา receipt ที่กำลังใช้งาน แต่ไม่อยู่ในตัวเลือก ให้มันสามารถแสดงได้
    use_this_taxAddresses: (state, getters, rootState) => {
        const receipts = Object.assign({}, state.paginated_metadata.taxAddresses.items);

        if (rootState.order.orderData?.receipt?.contact_id && typeof receipts[rootState.order.orderData.receipt.contact_id] === 'undefined') {
            receipts[rootState.order.orderData.receipt.contact_id] = Object.assign({}, rootState.order.orderData.receipt);
        }

        return receipts;
    },
    use_this_sortedTaxAddresses: (state, getters, rootState) => {
        const receipt_ids = [];
        let found = false;
        for (const receipt_id of state.paginated_metadata.taxAddresses.sortedItems) {
            if (rootState.order.orderData.receipt?.contact_id + '' === receipt_id + '') {
                found = true;
            }
            receipt_ids.push(receipt_id);
        }

        if (rootState.order.orderData.receipt?.contact_id && !found)
            receipt_ids.unshift(rootState.order.orderData.receipt?.contact_id);

        return receipt_ids;
    },
}
const mutations = {
    setAccessToken: (state, access_token) => {
        state.access_token = access_token;
    },
    setUserData: (state, payload) => {
        if (typeof payload.access_token !== 'undefined') {
            state.access_token = payload.access_token;
        }
        else if (typeof payload.lnw_access_token !== 'undefined') {
            state.access_token = payload.lnw_access_token;
        }
        state.userData = payload.userData;
    },
    [SET_CALLED]: (state, {methodName, flag}) => state.called[methodName] = !!flag,
    [SET_LINE_NOTIFY_TOKENS]: (state, line_notify_tokens) => state.line_notify_tokens = line_notify_tokens,

    [CLEAR_PAGINATED_METADATA]: (state) => {
        ['receiverAddresses', 'taxAddresses', 'dealerReceiverAddresses', 'senderAddresses'].forEach(type => {
            state.paginated_metadata[type].sortedItems = [];
            state.paginated_metadata[type].items = {};
            state.paginated_metadata[type].allMoreThanPageLimit = false;
            state.paginated_metadata[type].keyword = false;
            state.paginated_metadata[type].offset = 0;
            state.paginated_metadata[type].more = false;
            state.paginated_metadata[type].firstFetched = false;
        });
    },

    [SET_SEARCH_ADDRESSES_RESULT]: (state, {type, payload, keyword}) => {
        if (typeof payload !== 'object' || payload === null)
            return;

        if (typeof keyword !== "undefined") {
            state.paginated_metadata[type].sortedItems = [];
            state.paginated_metadata[type].offset = 0;
            state.paginated_metadata[type].keyword = keyword ? keyword : false;
        }

        if (Array.isArray(payload[type])) {
            const new_items = {...state.paginated_metadata[type].items};
            payload[type].forEach(item => {
                const key = item.contact_id;
                if (!state.paginated_metadata[type].sortedItems.includes(key))
                    state.paginated_metadata[type].sortedItems.push(key);
                if (typeof state.paginated_metadata[type].items[key] === "undefined")
                    new_items[key] = item;
            });
            state.paginated_metadata[type].items = new_items;


            if (typeof payload[`${type}_more`] !== "undefined")
                state.paginated_metadata[type].more = payload[`${type}_more`];

            if (!state.paginated_metadata[type].keyword) {
                state.paginated_metadata[type].firstFetched = true;

                if (state.paginated_metadata[type].offset === 0 && state.paginated_metadata[type].more) {
                    state.paginated_metadata[type].allMoreThanPageLimit = true;
                }
            }

            state.paginated_metadata[type].offset += payload[type].length;

        }
    },

    setAccountExists: (state, payload) => {
        state.accountExists.by = payload.exists;
        state.accountExists.name = payload.name;
        state.accountExists.avatar = payload.avatar;
    }
}
const actions = {
    async initUserData({commit, dispatch}) {
        let access_token = sessionStorage.getItem('lnw_access_token');
        if (access_token) {
            commit('setAccessToken', access_token);
            return dispatch('fetchUser');
        }
        else {
            // ยิงเพื่อให้รู้ว่า login อยู่หรือเปล่า (จาก cookie)
            return Axios.get(ApiUrl.account('check_session'))
                .then(response => {
                    if (response.data && response.data.data && response.data.data.lnw_access_token) {
                        sessionStorage.setItem('lnw_access_token', response.data.data.lnw_access_token);
                        commit('setUserData', response.data.data);
                    }
                });
        }
    },
    async fetchUser({commit, state}) {
        return Axios.get(ApiUrl.account('profile'),{
            headers: {
                'X-ACCESS-TOKEN': state.access_token
            },
        })
            .then((response) => {
                commit('setUserData', response.data.data);
            })
            .catch(() => {
                sessionStorage.removeItem('lnw_access_token'); // ถ้ายิงล้มเหลว ก็เคลีย session ใน sessionStorage
                commit('setAccessToken', null);
            });
    },
    async fetchLineTokens({commit, state, rootGetters}) {

        function generateRandomKey(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let randomKey = '';

            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                randomKey += characters.charAt(randomIndex);
            }

            return randomKey;
        }

        const randomKey = 'lnw'+generateRandomKey(16);
        const url = 'jsonp/list_line_notify_tokens?lnwmsg_jsonp='+randomKey;
        return Axios.get(ApiUrl.lnwmsg(url),
            {
                headers: rootGetters['order/axios_headers'],
                params: {
                    data: state.access_token,
                    limit: 10,
                    ajaxxxx: true,
                }
            })
            .then((response) => {
                if(response.data.includes(randomKey)){
                    const json_response = response.data.substring(response.data.indexOf('{'), response.data.lastIndexOf('}') + 1);

                    // Parse the JSON object
                    const parse_response = JSON.parse(json_response);

                    if(!parse_response.error){
                        commit(SET_LINE_NOTIFY_TOKENS, parse_response.tokens);
                        commit(SET_CALLED, {methodName: 'fetchLineTokens', flag: true});
                    }
                }
            });
    },

    async fetchUserResources({commit, state}, {preferred_data}) {
        if (preferred_data.length === 0)
            return;

        return Axios.get(ApiUrl.account('resources'), {
            headers: {
                'X-ACCESS-TOKEN': state.access_token
            },
            params: {
                preferred_data
            },
        })
            .then(response => {
                commit(SET_SEARCH_ADDRESSES_RESULT, {payload: response.data.data, type: 'receiverAddresses'});
                commit(SET_SEARCH_ADDRESSES_RESULT, {payload: response.data.data, type: 'taxAddresses'});
                commit(SET_SEARCH_ADDRESSES_RESULT, {payload: response.data.data, type: 'dealerReceiverAddresses'});
                commit(SET_SEARCH_ADDRESSES_RESULT, {payload: response.data.data, type: 'senderAddresses'});
            });
    },
    async checkAccountExists({commit}, {email, mobileNumber}) {
        commit('order/setForm', {ajaxBuyerInfoAccountExistsRunning: true}, {root: true});
        return Axios.get(ApiUrl.account('is_account_exists'), {
            params: {
                email,
                mobile: mobileNumber,
            },
        })
            .then((response) => {
                commit('setAccountExists', response.data.data);
            })
            .finally(() => commit('order/setForm', {ajaxBuyerInfoAccountExistsRunning: false}, {root: true}));
    },
    async displayErrorAfterAuthenticate({state}, response) {
        let error_message;
        // ไม่แน่ใจต้องใช้งานยังไงโดยที่ไม่ต้องมี param ที่ 1 ของ function นี้ เลยใส่ state ไว้เฉยๆ
        if (state && response.data) {
            if (Array.isArray(response.data))
                error_message = response.data.join(', ');
            else if (response.data.error_message && typeof response.data.error_message === 'object') {
                error_message = '';
                for (let k in response.data.error_message) {
                    error_message += `${response.data.error_message[k]}\n`;
                }
            }else if (response.data.error_message && typeof response.data.error_message === 'string') {
                error_message = response.data.error_message;
            }
            else
                error_message = JSON.stringify(response.data);
        }
        else
            error_message = 'undefined error_message';
        await store.dispatch('order/setIsAjaxResponse', false);
        await store.dispatch('order/setIsAjaxResponse', true);
        await store.dispatch('order/setIsAjaxResponseMessage', error_message);
        return false;
    },
    async singlePurposeAuthenticateByUsernamePassword({commit, dispatch}, {
        username, password, encoded_user_id,
        onFinally,
        onSuccess,
    }) {
        const postdata = {
            password: password,
            persistent: 1,
        }

        if (encoded_user_id)
            postdata.encoded_user_id = encoded_user_id
        else
            postdata.username = username

        try {
            const response = await Axios.post(ApiUrl.account('login_by_username'), postdata);
            if (response.data.success) {
                if (response.data.data.twoFactorToken) {
                    return {
                        twoFactorToken: response.data.data.twoFactorToken
                    }
                } else {
                    sessionStorage.setItem('lnw_access_token', response.data.data.lnw_access_token);
                    commit('setUserData', response.data.data);
                    if (onSuccess && typeof onSuccess === 'function')
                        onSuccess()
                    return true;
                }
            }
            else {
                return await dispatch('displayErrorAfterAuthenticate', response.data);

            }
        } catch (e) {
            // what to do ??
        } finally {
            if (onFinally && typeof onFinally === 'function')
                onFinally()
        }
    },
    async authenticateByUsernamePassword({commit, dispatch}, payload) {
        commit('order/setForm', {ajaxAuthenticateByUsernamePasswordRunning: true}, {root: true});

        const postdata = {
            password: payload.password,
            persistent: 1,
        };

        if (payload.encoded_user_id)
            postdata.encoded_user_id = payload.encoded_user_id;
        else
            postdata.username = payload.username;

        try {
            const response = await Axios.post(ApiUrl.account('login_by_username'), postdata);
            if (response.data.success) {
                if (response.data.data.twoFactorToken) {
                    return {
                        twoFactorToken: response.data.data.twoFactorToken
                    }
                } else {
                    await dispatch('afterAuthenticateGlobal', {
                        ...payload,
                        lnw_access_token: response.data.data.lnw_access_token,
                        user: response.data.data,
                    });
                    return true;
                }
            }
            else {
                return await dispatch('displayErrorAfterAuthenticate',response.data);
            }
        } catch (e) {
            //
        } finally {
            commit('order/setForm', {ajaxAuthenticateByUsernamePasswordRunning: false}, {root: true});
        }
    },
    async verifyOtp({dispatch}, payload) {
        const postdata = {
            code: payload.twoFactorCode,
            twoFactorToken: payload.twoFactorToken,
        }
        try {
            const response = await Axios.post(ApiUrl.account('verify_otp'), postdata)
            if (response.data.success) {
                await dispatch('afterAuthenticateGlobal', {
                    ...payload,
                    lnw_access_token: response.data.data.lnw_access_token,
                    user: response.data.data,
                    justLogin : payload.justLogin
                })
                return true
            }
            else {
                return await dispatch('displayErrorAfterAuthenticate',response.data);
            }
        } catch(e) {
            alert(e);
            return false;
        }

    },
    async logout({commit, state, dispatch}) {
        try {
            await Axios.post(ApiUrl.account('logout'), {}, {
                headers: {
                    'X-ACCESS-TOKEN': state.access_token
                },
            });

            sessionStorage.removeItem('lnw_access_token');

            commit('setUserData', {
                access_token: null,
                userData: null,
            });
            commit(CLEAR_PAGINATED_METADATA);
            commit('myOrders/CLEAR_PAGINATED_ORDERS', null, {root : true});

            await dispatch('order/fetchOrder', null, {root: true});
        }
        catch (e) {
            //
        }
    },

    async afterAuthenticateGlobal({commit, dispatch, getters, rootGetters}, payload) {
        sessionStorage.setItem('lnw_access_token', payload.lnw_access_token);
        commit('setUserData', payload.user);
        if (typeof payload.then === 'function') {
            await payload.then({dispatch});
        }
        else if(payload.justLogin){
            await store.dispatch('order/setIsLoginRequired', false);
        }else {
            dispatch('order/tracker_login', {method: 'user-id'}, {root: true});
            await store.dispatch('order/setIsLoginRequired', false);

            // หลังจาก login ให้ fbq init ใหม่ด้วย external_id เพราะตอนเข้าหน้าเว็บมาตอนแรก init ครั้งแรก ไม่ได้ระบุ external_id ไปด้วย
            if (Array.isArray(rootGetters['order/both_facebook_pixel_ids'])
                && rootGetters['order/both_facebook_pixel_ids'].length) {
                for (const id of rootGetters['order/both_facebook_pixel_ids']) {
                    fbq('init', id, {external_id: getters.loggedInUserId});
                }
            }

            // claim order
            await dispatch('order/claimOrder', null, {root:true});
        }
    },

    /**
     * ค้นหา
     * type: 'receiverAddresses' , 'taxAddresses' , 'dealerReceiverAddresses' , 'senderAddresses'
     */
    async searchAddresses({state, commit}, {type, keyword}) {
        if (!valid_address_type(type))
            return false;

        if (typeof keyword === "undefined" || typeof keyword === "boolean")
            keyword = "";
        else
            keyword = keyword.trim();
        if (keyword === '')
            keyword = false;

        if (state.paginated_metadata[type].keyword !== keyword) {
            return Axios.get(ApiUrl.account('resources'), {
                headers: {
                    'X-ACCESS-TOKEN': state.access_token
                },
                params: {
                    preferred_data: [type],
                    limit: default_paginated_limit,
                    offset: 0,
                    keyword: keyword ? keyword : null,
                },
            })
                .then(response => {
                    commit(SET_SEARCH_ADDRESSES_RESULT, {
                        type,
                        payload: response.data.data,
                        keyword: keyword,
                    });
                });
        }
    },

    async loadMoreAddresses({state, commit}, {type}) {
        if (!valid_address_type(type))
            return false;

        return Axios.get(ApiUrl.account('resources'), {
            headers: {
                'X-ACCESS-TOKEN': state.access_token
            },
            params: {
                preferred_data: [type],
                limit: default_paginated_limit,
                offset: state.paginated_metadata[type].offset,
                keyword: state.paginated_metadata[type].keyword ? state.paginated_metadata[type].keyword : null,
            },
        })
            .then(response => {
                commit(SET_SEARCH_ADDRESSES_RESULT, {
                    type,
                    payload: response.data.data,
                });
            });
    },
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};