import Axios from '@/libraries/Axios.js'
import ApiUrl from "@/libraries/ApiUrl";
import store from "@/store";

export default {
    async fetchOrders({state, rootGetters, commit}){
        await store.dispatch('order/setIsAjaxLoading', true);
        try {
            return Axios.get(ApiUrl.order_list({web_name: state.web_name}), {
                headers: rootGetters["user/axios_headers"],
            }).then(response=> {
                // commit('SET_ORDERS', response.data.orders);
                commit('SET_TOTAL_ORDERS', response.data.total_orders_count);
                commit('SET_SHOPDATA', response.data.shop);
                commit('order/setShopData', response.data.shop, {root: true});
                commit('SET_USERDATA_IN_SHOP', response.data.user_data);
                commit('order/setPaymentMethodsOnlyBankTransfer', response.data.payment_methods, {root: true})

                commit('SET_ORDERS_RESULT', {
                    status : 'all',
                    payload: response.data.orders,
                });
                store.dispatch('order/setIsAjaxLoading', false);
            });
        } catch(e) {
            // what to do ??
        }
    },
    async loadMoreOrders({state, rootGetters, commit}, {status, limit = 20}){
        await store.dispatch('order/setIsAjaxLoading', true);
        if(!state.paginated_metadata[status])
            return false;
        try {
            return Axios.get(ApiUrl.order_list({web_name: state.web_name}), {
                headers: rootGetters["user/axios_headers"],
                params: {
                    stage: (status != 'all')?status:null,
                    limit: limit,
                    offset: state.paginated_metadata[status].offset,
                    keyword: state.paginated_metadata[status].keyword ? state.paginated_metadata[status].keyword : null,
                },
            })
                .then(response => {
                    commit('SET_ORDERS_RESULT', {
                        status : status,
                        payload: response.data.orders,
                    });
                });
        } catch(e) {
            // what to do ??
        }
    },
}