import {mergeDeep, noop} from "../lnw-vue-gtag/util.js";
import bootstrap from "./bootstrap.js";

let Vue;

export let options = {
    config: {
        id: null, // pixel id
    },
    // plugin's events
    onReady: noop,
    onError: noop,
    // no need to touch
    bootstrap: false,
    globalObjectName: "fbq",
    customResourceURL: "https://connect.facebook.net/th_TH/fbevents.js",
    customPreconnectOrigin: "https://connect.facebook.net",
    deferScriptLoad: false,
};

export const getVue = () => Vue;
export const getOptions = () => options;
export const setOptions = (_options) => mergeDeep(options, _options);
export const fbq = function (...args) {
    if (typeof document === "undefined" || typeof window === "undefined") {
        return;
    }

    if (typeof window[options.globalObjectName] !== "function") {
        return;
    }

    window[options.globalObjectName](...args);
};

export function install(_Vue, _options = {}) {
    Vue = _Vue;

    setOptions(_options);

    // extend
    Vue.$pixel = Vue.prototype.$pixel = fbq;

    // bootstrap
    if (options.bootstrap)
        bootstrap();
}