import {mergeDeep, noop} from "../lnw-vue-gtag/util.js";
import bootstrap from "./bootstrap.js";

let Vue;

export let options = {
    config: {
        id: null, // pixel id
    },
    // plugin's events
    onReady: noop,
    onError: noop,
    // no need to touch
    bootstrap: true,
    globalObjectName: "_lt",
    globalDataLayerName: "_ltq",
    customResourceURL: "https://d.line-scdn.net/n/line_tag/public/release/v1/lt.js",
    customPreconnectOrigin: "https://d.line-scdn.net",
    deferScriptLoad: false,
};

export const getVue = () => Vue;
export const getOptions = () => options;
export const setOptions = (_options) => mergeDeep(options, _options);
export const _lt = function (...args) {
    if (typeof document === "undefined" || typeof window === "undefined") {
        return;
    }

    if (typeof window[options.globalObjectName] !== "function") {
        return;
    }

    window[options.globalObjectName](...args);
};

export function install(_Vue, _options = {}) {
    Vue = _Vue;

    setOptions(_options);

    // extend
    Vue.$line = Vue.prototype.$line = _lt;

    // bootstrap
    if (options.bootstrap)
        bootstrap();
}