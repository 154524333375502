function generateUUID() {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); // use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export const formInject = (action, method, inputs, id) => {
    const form = window.document.createElement('form');
    form.action = action;
    form.method = method;

    if (id) {
        form.id = id;
    } else {
        form.id = generateUUID();
    }

    form.style.display = 'none';

    for (const input of inputs) {
        const DOM = window.document.createElement('input');

        if (input.type)
            DOM.type = input.type;
        else
            DOM.type = 'hidden';

        if (input.name)
            DOM.name = input.name;

        if (input.value)
            DOM.value = input.value;

        if (input.attributes) {
            for (const attrName in input.attributes) {
                DOM.setAttribute(attrName, input.attributes[attrName]);
            }
        }

        form.appendChild(DOM);
    }

    document.body.append(form);

    return form;
};

export const injectFormThenSubmit = (action, method, inputs) => {
    const form = formInject(action, method, inputs);
    form.submit();
};

export const injectInvoiceFormPropertiesThenSubmit = (invoiceFormProperties) => {
    const inputs = [];
    for (const key in invoiceFormProperties.args) {
        inputs.push({
            name: key,
            value: invoiceFormProperties.args[key],
        });
    }
    injectFormThenSubmit(
      invoiceFormProperties.action,
      invoiceFormProperties.method,
      inputs
    );
}