import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
const initialState = {
    web_name: null,
    shopData: null,
    orders: null,
    sortedOrders: null,
    total_orders_count: null,
    userDataInShop: null,
    paginated_metadata: {
        /**
         * ถ้าจะเพิ่ม properties ตรงนี้ ให้ไปเพิ่มที่ mutations.CLEAR_PAGINATED_ORDERS ด้วย
         */
        all: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        wait_order_confirm: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        wait_payment: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        wait_payment_verify: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        wait_receiver_info: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        wait_send: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        sent: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        wait_receive: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        wait_confirm: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        completed: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        expired: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        refund: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        buyer_cancelled: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        seller_cancelled: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        suspended: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: true, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
    },
}

export default {
    namespaced: true,
    state: () => initialState,
    actions,
    getters,
    mutations,
}