import Axios from "@/libraries/Axios.js";
import ApiUrl from "@/libraries/ApiUrl.js";

const SET_POINT = 'SET_POINT';
const SET_CALLED = 'SET_CALLED';
const SET_CREDIT_CARD_METADATA = 'SET_CREDIT_CARD_METADATA';

const state = {
    // ไว้เก็บว่าเคยดึงข้อมูลอะไรมาบ้างแล้ว จะได้ไม่ต้องดึงซ้ำอีก
    called: {
        fetchLnwPayInfo: false,
    },

    point: {
        available_balance: null,
        available_money: null,
        point_value: null,
    },

    creditCardTokenList: false,
};

const getters = {};

const mutations = {
    [SET_POINT]: (state, point) => state.point = point,
    [SET_CALLED]: (state, {methodName, flag}) => state.called[methodName] = !!flag,
    [SET_CREDIT_CARD_METADATA]: (state, data) => {
        state.creditCardTokenList = data.credit_card_token_list;
    },
};

const actions = {
    async fetchLnwPayInfo({commit, rootGetters}) {
        const url = ApiUrl.pay('profile');

        return Axios.get(url, {headers: rootGetters['order/axios_headers']})
            .then((response) => {
                if (!response.error) {
                    commit(SET_POINT, response.data.point);
                    if (response.data.credit_card_token_list) {
                        commit(SET_CREDIT_CARD_METADATA, response.data);
                    }
                    commit(SET_CALLED, {methodName: 'fetchLnwPayInfo', flag: true});
                }
            });
    },
    async orderConfirmReceived({rootGetters, dispatch}, {order_uuid}) {
        const url = ApiUrl.pay(`order/confirm_received/${order_uuid}?s=${rootGetters['order/lnwshop_s']}&us=${rootGetters['order/lnwpay_us']}`);

        return Axios.post(url, {}, {headers: rootGetters['order/axios_headers']})
            .then((response) => {
                if (!response.error) {
                    dispatch('order/fetchOrder', null, {root: true});
                }
            });
    },
    async payByKplus({rootGetters}, {sid, oid, amount, us, mobile}) {
        const url = ApiUrl.pay('pay/kplus');
        const response = await Axios.post(url, {sid, oid, amount, us, mobile}, {headers: rootGetters['order/axios_headers']});

        return response?.data;
    },
    async acquire2c2pPaymentAuthPayload({rootGetters}, { form_data, return_data }) {
        const provider = rootGetters['order/creditCardProvider'];
        const uri = `gateway/${provider}`;
        let url;
        if (rootGetters['order/creditCardIsTest']) {
            url = ApiUrl.testpay(uri);
        } else {
            url = ApiUrl.pay(uri);
        }

        if (rootGetters['order/orderUUID'])
            form_data.refus = rootGetters['order/orderUUID'];
        else
            form_data.refus = return_data.fallback_order_UUID;

        if (return_data.latest_lnwpay_us)
            form_data.us = return_data.latest_lnwpay_us;

        form_data.ptype = 'shop';

        const response = await Axios.post(url, form_data, { headers: rootGetters['order/axios_headers'] });

        return response?.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};