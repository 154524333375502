import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions.js";
import { getItem, LNWPAY_CALLBACK_STATES_KEY } from "@/libraries/OrderLocalStorage";

const lnwpayCallbackStatesDataString = getItem(LNWPAY_CALLBACK_STATES_KEY)
const lnwpayCallbackStates = lnwpayCallbackStatesDataString
  ? JSON.parse(lnwpayCallbackStatesDataString)
  : null;

const initialState = {
  type: null, // order | cart
  web_name: null,
  order_id: null,
  secret: null,
  is_x: false,

  existingOrderOwner: null,
  orderData: null,
  shopData: null,
  mallData: null,
  form: {
    shipping_type_id: null,
    coupon_code: '',
    buyerInfoEmail: '',
    buyerInfoMobileNumber: '',
    smsCheck: false,
    adsCheck: false,
    lineNotifyCheck: false,
    buyerMixInfoConfirmed: false, // กดตกลงว่าจะใช้อันนี้แล้ว
    buyerInfoConfirmed: false, // กดตกลงว่าจะใช้อันนี้แล้ว
    forceAsGuest: false,
    buyerInfoAlreadyLogin: false,
    buyerInfoAccountExists: null,

    kPlusMobileNumber: '',

    isPreferToChangePaymentMethod: false,
    paymentMethodInputValue: null,

    // 0=ไม่ได้เปิดใช้ฟอร์ม  -1=กำลังสร้างรายการใหม่  id=กำลังแก้ไขรายการไหน
    editAddressId: 0,
    // ในฟอร์มกำลังเลือกตัวเลือกไหนอยู่
    shippingMethodShippingAddressId: 0,
    // ในฟอร์มกำลังเลือกประเภทของที่อยู่ว่าเป็นของฉันหรือของลูกค้า (เฉพาะตัวแทน)
    shippingMethodShippingDealerAddress: false,
    // 0=ไม่ได้เปิดใช้ฟอร์ม  -1=กำลังสร้างรายการใหม่  id=กำลังแก้ไขรายการไหน
    editTaxAddressId: 0,
    // ในฟอร์มกำลังเลือกตัวเลือกไหนอยู่
    selectedReceiptContactId: null,
    receiptCheckboxChecked: false,

    // 0=ไม่ได้เปิดใช้ฟอร์ม  -1=กำลังสร้างรายการใหม่  id=กำลังแก้ไขรายการไหน
    editSenderAddressId: 0,
    // ในฟอร์มกำลังเลือกตัวเลือกไหนอยู่
    selectedSenderContactId: null,

    ajaxSaveBuyerInfoRunning: false,
    ajaxBuyerInfoAccountExistsRunning: false,
    ajaxAuthenticateByUsernamePasswordRunning: false,

    // เมื่อ ลูกค้า กดว่าต้องการแจ้งโอนเงินใหม่ จะเป็น true
    preferReinformPayment: false,
    // แสดง modal dialog แจ้งโอนเงินมั้ย
    isShowPaymentDialog: false,

    // แสดงกล่อง TheOrderDetail มั้ย
    isExpandOrderDetail: false,
    isOrderFixedTop: false,

    //ต้องการใบเสนอราคาก่อนชำระเงินไหม
    isRequestQuote: false,
  },
  couponCodes: [],
  stepMetas: {
    1:{open: true},
    2:{open: false},
    3:{open: false},
  },
  isShowOverlay: false,
  isShowOverlayPayment: false,
  isAjaxLoading: false,
  isAjaxResponse: false,
  isAjaxResponseMessage: '',
  isCopied: false,
  isLoginRequired: false,
  enableSetIsAjaxResponse: false, // เอาไว้ดักเงื่อนไข ที่จะไม่ให้ snackbar แสดงในตอนเข้าครั้งแรก

  informDialog : false,

  // kplus
  kplusDialogFlag: false,
  kplusPaymentUrl: 'about:blank',

  // dedicated online payment web-form
  dedicatedOnlinePaymentForm: {
    action: '',
    method: '',
    args: {},
  },

  // facebook checkbox plugin
  facebook_checkbox_plugin_checkbox_state: null,
  facebook_checkbox_plugin_checkbox_rendered_time: (new Date()).getTime(),

  // สำหรับเก็บค่าจาก Query String ที่ LnwPay redirect กลับมา เพื่อแสดงผลสถานะ การชำระเงิน
  lnwpayCallbackStatesJustEntered: false, // เพิ่ง redirect กลับมาใช่หรือเปล่า
  lnwpayCallbackStates, // จะอ่านจาก localStorage มาให้ด้วย ถ้าไม่มีใน queryString
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
  actions,
};