export const SET_IS_SHOW_OVERLAY = 'SET_IS_SHOW_OVERLAY';
export const SET_IS_SHOW_OVERLAY_PAYMENT = 'SET_IS_SHOW_OVERLAY_PAYMENT';
export const SET_IS_AJAX_LOADING = 'SET_IS_AJAX_LOADING';
export const SET_IS_AJAX_RESPONSE = 'SET_IS_AJAX_RESPONSE';
export const SET_IS_AJAX_RESPONSE_MESSAGE = 'SET_IS_AJAX_RESPONSE_MESSAGE';
export const SET_IS_COPIED = 'SET_IS_COPIED';
export const SET_IS_LOGIN_REQUIRED = 'SET_IS_LOGIN_REQUIRED';
export const SET_ENABLE_SET_IS_AJAX_RESPONSE = 'SET_ENABLE_SET_IS_AJAX_RESPONSE';
export const SET_INFORM_DIALOG = 'SET_INFORM_DIALOG';
export const SET_KPLUS_DIALOG = 'SET_KPLUS_DIALOG';
export const SET_LNWPAY_CALLBACK_STATES = 'SET_LNWPAY_CALLBACK_STATES';