import Vue from 'vue'
import Vuex from 'vuex'
import order from './modules/order';
import user from './modules/user';
import lnwpay from "./modules/lnwpay.js";
import device from './modules/device';
import myOrders from "@/store/modules/myOrders"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    order, // ต้องระบุ key แบบนี้ เพราะ phpStorm มัน bug แล้วไม่เจอ VueX module user
    user: user, // ต้องระบุ key แบบนี้ เพราะ phpStorm มัน bug แล้วไม่เจอ VueX module user
    lnwpay: lnwpay, // ต้องระบุ key แบบนี้ เพราะ phpStorm มัน bug แล้วไม่เจอ VueX module user
    myOrders: myOrders,
    device
  }
})
