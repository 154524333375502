export default {
    SET_WEB_NAME: (state, web_name) => {
        state.web_name = web_name;
    },
    // SET_ORDERS: (state, orders) => {
    //   state.orders = orders;
    // },
    SET_TOTAL_ORDERS: (state, number) => {
        state.total_orders_count = number;
    },
    SET_SHOPDATA: (state, shopData) => {
        state.shopData = shopData;
    },
    SET_USERDATA_IN_SHOP: (state, user_data) => {
        state.userDataInShop = user_data;
    },
    CLEAR_PAGINATED_ORDERS: (state) => {
        state.sortedOrders = {};
        state.paginated_metadata.forEach(status => {
            state.paginated_metadata[status].sortedItems = [];
            state.paginated_metadata[status].items = {};
            state.paginated_metadata[status].allMoreThanPageLimit = false;
            state.paginated_metadata[status].keyword = false;
            state.paginated_metadata[status].offset = 0;
            state.paginated_metadata[status].more = false;
            state.paginated_metadata[status].firstFetched = false;
        });
    },

    SET_ORDERS_RESULT: (state, {status, payload}) => {
        if(!Array.isArray(payload) || payload === null)
            return;

        const new_items = {...state.sortedOrders};
        payload.forEach(item => {
            const key = item.order_id;
            const item_status = item.status;

            // ไม่ต้องทับเพราะมีการดึงมาใหม่จะได้ข้อมูลที่อัพเดทเสมอ
            // if(!new_items[key]){
            new_items[key] = item;
            // }
            //สำหรับโหลดจาก All ให้แตก offset ไปที่แต่ละ status
            if(status == 'all'){
                state.paginated_metadata[item_status].offset++;
            }
        });
        state.sortedOrders = new_items;

        if(payload.length < 20)
            state.paginated_metadata[status].more = false;

        if(!state.paginated_metadata[status].keyword){
            state.paginated_metadata[status].firstFetched = true;

            if(state.paginated_metadata[status].offset === 0 && state.paginated_metadata[status].more){
                state.paginated_metadata[status].allMoreThanPageLimit = true;
            }
        }

        state.paginated_metadata[status].offset += payload.length;
    },
}