import { mergeDeep } from "../lnw-vue-gtag/util.js";

export let options = {
    config: {
        id: null, // pixel id
    },
    bootstrap: false,
    globalObjectName: "ttq",
    customResourceURL: "https://analytics.tiktok.com/i18n/pixel/events.js",
};

export const getOptions = () => options;
export const setOptions = (_options) => mergeDeep(options, _options);