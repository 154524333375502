import {getOptions} from "./install.js";
import {loadScript, isFn} from "../lnw-vue-gtag/util.js";

export default function () {
    if (typeof document === "undefined" || typeof window === "undefined") {
        return;
    }

    const {
        deferScriptLoad,
        globalObjectName,
        globalDataLayerName,
        customResourceURL,
        customPreconnectOrigin,
        onReady,
        onError,
    } = getOptions();

    // ถ้า initialized แล้ว ไม่ต้องทำซ้ำอีก
    if (window[globalObjectName]) return;

    window[globalDataLayerName] = window[globalDataLayerName] || [];

    window[globalObjectName] = window[globalObjectName] || function() {
        window[globalDataLayerName].push(arguments);
    };

    // noinspection DuplicatedCode
    return loadScript(customResourceURL, {
        preconnectOrigin: customPreconnectOrigin,
        defer: deferScriptLoad,
    })
        .then(() => {
            const library = window[globalObjectName];

            if (isFn(onReady)) {
                onReady(library);
            }

            return library;
        })
        .catch(error => {
            if (isFn(onError)) {
                onError(error);
            }

            return error;
        });
}