import axios from "axios";
import store from "@/store";
import i18n from "@/i18n.js";

axios.interceptors.request.use(config => {
        if (!config.bypass_ajax_loading)
            store.dispatch('order/setIsAjaxLoading', true);
        return config;
    },
    error => {
        store.dispatch('order/setIsAjaxLoading', false);
        return Promise.reject(error);
    });

axios.interceptors.response.use(
    (response) => {
        if (!response.config?.bypass_ajax_loading) {
            store.dispatch('order/setIsAjaxLoading', false);

            if (store.state.order.enableSetIsAjaxResponse) {
                store.dispatch('order/setIsAjaxResponse', true);
            }
        }

        if (response.data.error) {
            // @todo friendly display error
            // alert('ERROR' + "\n" + response.data.error_messages.join("\n"));

            let html = '';
            response.data.error_messages.forEach( function (err, idx){

                let error_key = false;
                if(err.key){
                    error_key = err.key;
                }else if(typeof err === 'string'){
                    error_key = err;
                }
                if(error_key){
                    switch(error_key){
                        case 'NOPRODUCT':
                        case 'HIDDEN':
                        case 'NOSELL':
                            html = 'ไม่มีสินค้าชนิดนี้ หรือสินค้าชนิดนี้ไม่ขาย';
                            break;
                        case 'NOSTOCK':
                            html = 'สินค้านี้เหลือไม่เพียงพอ (เหลือ %d ชิ้น)';
                            html = html.replace(/%d/,err.remain);
                            break;
                        case 'BUY-WEB_CORRUPTION':
                            html = 'ขออภัยค่ะ ขณะนี้ระบบโดนระงับ ไม่สามารถสั่งซื้อสินค้าได้ค่ะ';
                            break;
                        case 'INVALID_POINT':
                            html = 'กรุณากรอกตัวเลขเพื่อใช้คะแนนสะสม LnwPoints ค่ะ';
                            break;
                        case 'INVALID_BANK':
                            html = 'กรุณาเลือกธนาคารที่ถูกต้องค่ะ';
                            break;
                        case 'USE>POINT':
                            html = 'ไม่สามารถใช้คะแนนสะสม LnwPoints เกินกว่าคะแนนที่คุณมีได้ค่ะ';
                            break;
                        case 'POINT>NET':
                            html = 'ไม่สามารถใช้คะแนนสะสม LnwPoints มากกว่าราคารวมสินค้าได้ค่ะ';
                            break;
                        case 'BUY-LNWPAY_ANTI-MONEY_LAUNDERING':
                            html = 'ระบบไม่อนุญาติให้สั่งซื้อสินค้าราคารวมเกิน 30,000 บาทต่อรายการสั่งซื้อได้ค่ะ';
                            break;
                        case 'PERMISSION_DENIED':
                            html = 'คุณไม่สามารถเข้าถึงได้ กรุณาเข้าสู่ระบบ';
                            break;
                        case 'INVALID_SHIPPINGTYPE':
                            html = 'วิธีการจัดส่งผิดพลาดค่ะ';
                            break;
                        case 'BELOW_QUANTITY':
                            html = i18n.t('กรุณาสั่งซื้อขั้นต่ำ') +' %d %s';
                            html = html.replace(/%d/,err.limit);
                            html = html.replace(/%s/,i18n.t(err.unit));
                            break;
                        case 'OVER_QUANTITY':
                            html = i18n.t('กรุณาสั่งซื้อไม่เกิน') +' %d %s';
                            html = html.replace(/%d/,err.limit)
                            html = html.replace(/%s/,i18n.t(err.unit));
                            break;
                        case 'ACCOUNT_LIMIT_REQUIRED_LOGIN':
                            html = 'สินค้าชิ้นนี้จำกัดการซื้อต่อ 1 คน';
                            break;
                        case 'OVER_QUANTITY_ACCOUNT_ORDERED':
                            html = i18n.t('จำกัดจำนวนการซื้อต่อ 1 คน ไม่เกิน') + ' %d %s' + i18n.t('คุณซื้อไปแล้ว') +' %d %s';
                            html = html.replace(/%d/,err.limit)
                            html = html.replace(/%s/,i18n.t(err.unit))
                            html = html.replace(/%d/,err.ordered);
                            html = html.replace(/%s/,i18n.t(err.unit));
                            break;
                        case 'OVER_QUANTITY_ACCOUNT':
                            html = i18n.t('จำกัดจำนวนการซื้อต่อ 1 คน ไม่เกิน') +' %d %s';
                            html = html.replace(/%d/,err.limit)
                            html = html.replace(/%s/,i18n.t(err.unit))
                            break;
                        case 'PRODUCT-OVER_QUANTITY':
                            html = i18n.t('จำกัดจำนวนการซื้อไม่เกิน') + ' %d %s';
                            html = html.replace(/%d/,err.limit)
                            html = html.replace(/%s/,i18n.t(err.unit));
                            break;
                        case 'OVER_TOTAL_QUANTITY':
                            html = i18n.t('จำกัดจำนวนการซื้อทั้งตะกร้าไม่เกิน') + ' %d %s';
                            html = html.replace(/%d/,err.limit)
                            html = html.replace(/%s/,i18n.t(err.unit));
                            break;
                        case 'ORDER_LIMIT_MAX_PRICE':
                            html = i18n.t('กรุณาสั่งซื้อไม่เกิน') + ' %s ' + i18n.t('บาท');
                            html = html.replace(/%s/,response.data.error_data);
                            break;
                        case 'ORDER_LIMIT_MIN_PRICE':
                            html = i18n.t('กรุณาสั่งซื้อขั้นต่ำ') + ' %s ' + i18n.t('บาท');
                            html = html.replace(/%s/,response.data.error_data);
                            break;
                        case 'COUPON_CODE_NOT_FOUND':
                            html = 'ขออภัยค่ะ ไม่สามารถใช้คูปองได้ เนื่องจากกรณีเช่น ไม่พบคูปองนี้, คูปองนี้หมดลงแล้ว หรือไม่เข้าเงื่อนไขของคูปอง';
                            break;
                        default:
                            html = error_key;
                            break;
                    }

                }else{
                    if(idx)
                        html += '\n';
                    html += err;
                }

            });
            if (store.state.order.enableSetIsAjaxResponse) {
                store.dispatch('order/setIsAjaxResponseMessage', html);
            }
            //return Promise.reject(response.data.error_messages);
        }

        return response;
    },
    async(error) => {
        await store.dispatch('order/setIsAjaxLoading', false);
        await store.dispatch('order/setIsAjaxResponse', false);
        await store.dispatch('order/setIsAjaxResponse', true);
        await store.dispatch('order/setIsAjaxResponseMessage', error);

        return Promise.reject(error);
    });

export default axios;