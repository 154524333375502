export const isLocalhost = () => {
    return typeof window.webpackHotUpdate !== 'undefined';
}
export const inDevelopment = () => {
    return typeof window.webpackHotUpdate !== 'undefined' && window.location.href.match(/&develop=1/);
}
export const inDevelopmentKplus = () => {
    return typeof window.webpackHotUpdate !== 'undefined' && window.location.href.match(/&develop=kplus/);
}


export const ucwords = (str) => {
    return str.toLowerCase().replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
        function(s){
            return s.toUpperCase();
        });
}

const get_mall_categories = item => item.mall_categories && Array.isArray(item.mall_categories) ? item.mall_categories : [];
const get_shop_categories = item => {
    if (item.categories && Array.isArray(item.categories))
        return item.categories;
    else if (item.category)
        return [item.category];
    else
        return [];
};

const product_to_base_google_item = (item, { brand, categories }) => {
    const google_item = {
        item_id: item.product_uuid,
        item_name: item.title,
        item_brand: brand,
        id: item.product_uuid,
        name: item.title,
        brand: brand,
        currency: 'THB',
        quantity: item.quantity,
        price: item.unit_price,
    };

    if (Array.isArray(categories)) {
        google_item.category = categories.map((i)=>i.replace("/", "-")).join("/");
        for (let i = 0; i < categories.length; i++) {
            const key = 'item_category' + (i>0 ? i+1 : '');
            google_item[key] = categories[i];
        }
    }

    return google_item;
}

/**
 *
 * @param item
 * @returns {{quantity: (number|*), item_id: *, price, name, item_name, currency: string, id: *, item_brand: (*|string), brand: (*|string)}}
 */
export const shop_product_to_google_item = item => product_to_base_google_item(item, {
    categories: get_shop_categories(item),
    brand: (item.brand ? item.brand : ''),
});
/**
 *
 * @param item
 * @returns {{quantity: (number|*), item_id: *, price, name, item_name, currency: string, id: *, item_brand: (*|string), brand: (*|string)}}
 */
export const mall_product_to_google_item = item => product_to_base_google_item(item, {
    categories: get_mall_categories(item),
    // ในกรณีพิเศษ เช่น TBF : backend จะส่ง mall_brand เป็นชื่อร้านมายิงตรง brand เพราะร้านหนังสือไม่กำหนด brand กัน ในหลังร้านตัวเอง เจ้าของ Mall อยากใช้ dimension brand ให้เกิดประโยชน์
    // ถ้ามี mall_brand กำหนดมา ให้ยิง mall_brand
    brand: item.mall_brand
      ? item.mall_brand
      : (
        // ถ้าไม่มี mall_brand ค่อยดูที่ item.brand (สำหรับกรณีที่ไม่พิเศษ แบบ TBF)
        item.brand
          ? item.brand
          : ""
      ),
});