import {getOptions} from "./install.js";

export const get_ttq = function () {
  if (typeof document === "undefined" || typeof window === "undefined") {
    return;
  }

  const {
    globalObjectName,
    customResourceURL,
  } = getOptions();

  if (window[globalObjectName])
    return window[globalObjectName];

  const t = globalObjectName;

  window.TiktokAnalyticsObject = globalObjectName;
  const ttq = window[globalObjectName] = window[globalObjectName] || [];
  ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"];
  ttq.setAndDefer = function (t, e) {
    t[e] = function () {
      t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
    }
  };
  for (let i = 0; i < ttq.methods.length; i++) {
    ttq.setAndDefer(ttq, ttq.methods[i]);
  }
  ttq.instance = function (t) {
    const e = ttq._i[t] || [];
    for (let n = 0; n < ttq.methods.length; n++) {
      ttq.setAndDefer(e, ttq.methods[n]);
    }
    return e;
  };

  ttq.load = function (e, n) {
    const i = customResourceURL;

    ttq._i = ttq._i || {};
    ttq._i[e] = [];
    ttq._i[e]._u = i;
    ttq._t = ttq._t || {};
    ttq._t[e] = +new Date;
    ttq._o = ttq._o || {};
    ttq._o[e] = n || {};

    const o = document.createElement("script");
    o.type = "text/javascript";
    o.async = !0;
    o.src = i + "?sdkid=" + e + "&lib=" + t;
    const a = document.getElementsByTagName("script")[0];
    a.parentNode.insertBefore(o, a);
  }

  return window[globalObjectName];
}