export default {
    order_list({web_name}) {
        return `/api/shop/${web_name}/order/list`;
    },
    order({web_name, order_id, secret, type}) {
        return `/api/shop/${web_name}/${type}/${order_id}?s=${secret}`;
    },
    lnwshop({web_name}, uri) {
        return `/api/shop/${web_name}/${uri}`;
    },
    lnwshopwebUrl(uri) {
        return `/api/lnwshopweb/${uri}`;
    },
    pay(uri) {
        return `/api/pay/${uri}`;
    },
    testpay(uri) {
        return `/api/testpay/${uri}`;
    },
    account(uri) {
        return `/account/api/${uri}`;
    },
    lnwmsg(uri) {
        return `/api/msg/${uri}`;
    },
}