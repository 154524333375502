export const LNWPAY_CALLBACK_STATES_KEY = 'PCS';

const OLSDB = 'OLSDB';

const shopKey = (key) => {
  let pathName = window.location.pathname;
  if (pathName.match(/^\/(order|cart)\/[^/]+\/[0-9]+/)) {
    return `${pathName}/${key}`;
  } else {
    return null;
  }
};

const removeExpiredData = (db) => {
  const now = (new Date()).getTime();
  return JSON.stringify(db.filter((pair) => {
    if (pair[1] < now) { // expired
      window.localStorage.removeItem(pair[0]);
      return false;
    } else {
      return true;
    }
  }));
}

const getOLSDB = () => {
  const str = window.localStorage.getItem(OLSDB);
  return str ? JSON.parse(str) : [];
}

const setOLSDB = (db) => window.localStorage.setItem(OLSDB, removeExpiredData(db));

const pushKey = (key, ttl) => {
  const db = getOLSDB();
  db.push([key, (new Date()).getTime() + (ttl * 1000)]);
  setOLSDB(db);
};

/**
 * @param key
 * @param dataString
 * @param ttl [seconds]
 */
export const setItem = (key, dataString, ttl=3600) => {
  const _key = shopKey(key);
  window.localStorage.setItem(_key, dataString);
  pushKey(_key, ttl);
};

export const getItem = (key) => {
  const _key = shopKey(key);
  const db = getOLSDB();
  for (const i of db.keys()) {
    const pair = db[i];
    if (pair[0] === _key) {
      if (pair[1] < (new Date()).getTime()) { // expired
        db.splice(i, 1);
        setOLSDB(db);
        return null;
      } else {
        return window.localStorage.getItem(_key);
      }
    }
  }

  return null;
};