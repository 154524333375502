import {mergeDeep, noop} from "./util.js";
import bootstrap from "./bootstrap.js";

let Vue;

export let options = {
    config: {
        id: null,
    },
    // plugin's events
    onReady: noop,
    onError: noop,
    // no need to touch
    globalObjectName: "gtag",
    globalDataLayerName: "dataLayer",
    customResourceURL: "https://www.googletagmanager.com/gtag/js",
    customPreconnectOrigin: "https://www.googletagmanager.com",
    deferScriptLoad: false,
};

export const getVue = () => Vue;
export const getOptions = () => options;
export const setOptions = (_options) => mergeDeep(options, _options);

export const gtag = function (...args) {
    if (typeof document === "undefined" || typeof window === "undefined") {
        return;
    }

    if (typeof window[options.globalObjectName] !== "function")
        return;

    window[options.globalObjectName](...args);
};

export function install(_Vue, _options = {}) {
    Vue = _Vue;

    setOptions(_options);

    // extend
    Vue.$gtag = Vue.prototype.$gtag = gtag;

    // bootstrap
    bootstrap();
}

