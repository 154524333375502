import {getOptions} from "./install.js";
import {loadScript, isFn} from "./util.js";

export default function () {
    if (typeof document === "undefined" || typeof window === "undefined") {
        return;
    }

    const {
        config,
        deferScriptLoad,
        globalDataLayerName,
        globalObjectName,
        customResourceURL,
        customPreconnectOrigin,
        onReady,
        onError,
    } = getOptions();

    if (window[globalObjectName] == null) {
        window[globalDataLayerName] = window[globalDataLayerName] || [];
        window[globalObjectName] = function () {
            window[globalDataLayerName].push(arguments);
        };
    }
    window[globalObjectName]("js", new Date());

    if (config.id)
        window[globalObjectName]('config', config.id);

    const gtag_js_url =
        `${customResourceURL}?` +
        (config.id ? `id=${config.id}&` : "") +
        `l=${globalDataLayerName}`;

    return loadScript(gtag_js_url, {
        preconnectOrigin: customPreconnectOrigin,
        defer: deferScriptLoad,
    })
        .then(() => {
            const library = window[globalObjectName];

            if (isFn(onReady)) {
                onReady(library);
            }

            return library;
        })
        .catch(error => {
            if (isFn(onError)) {
                onError(error);
            }

            return error;
        });
}