import { gtag } from "@/packages/lnw-vue-gtag/install.js";

/**
 * ตอนแรก พยายามจะใช้ feature send_to: {group-name} ของ gtag
 * แต่พบว่ามันมีปัญหาใช้งานไม่ได้จริง ตอนนี้เลยปิดกลับมาเป็น false
 * แล้วยิงแบบ send_to: [ ...gtag_ids ] แทน
 * @type {boolean}
 */
export const GTAG_TARGET_GROUP_ENABLE = false;

export const orderAjaxHandle = (response, {commit, dispatch, rootState, rootGetters}) => {
  if (!response.data.error) {

    if (response.data.existing_owner) {
      commit('setOrderData', {
        existing_owner: response.data.existing_owner,
        orderData: response.data.cart,
        form_selectedReceiptContactId: response.data.cart?.receipt_id,
      });
    }
    else {

      // ถ้า order มีการเปลี่ยนสถานะ lnwpay ให้ reload หน้าเว็บไปเลย
      if (
        rootState.order.orderData
        && typeof rootState.order.orderData.lnwpay_flag !== 'undefined'
        && typeof response.data.cart?.lnwpay_flag !== 'undefined'
        && rootState.order.orderData.lnwpay_flag !== parseInt(response.data.cart.lnwpay_flag)
      ) {
        window.location.reload();
        return;
      }

      let email = '';
      let mobile = '';
      if (response.data.cart?.contact_email || response.data.cart?.contact_mobile) {
        email = response.data.cart?.contact_email;
        mobile = response.data.cart?.contact_mobile;
      }
      else {
        if (rootState.user.userData?.email)
          email = rootState.user.userData.email;
        if (rootState.user.userData?.mobile)
          mobile = rootState.user.userData.mobile;
      }

      // convert numeric string flag as Integer
      if(response.data.cart){
        for(const key of ['lnwpay_flag']) {
          try {
            response.data.cart[key] = parseInt(response.data.cart[key]);
          } catch(e) {
            response.data.cart[key] = 0;
          }
        }
      }

      commit('setOrderData', {
        existing_owner: null,
        orderData: response.data.cart,
        form_email: email,
        form_mobile: mobile,
        form_selectedReceiptContactId: response.data.cart?.receipt_id,
      });

      // ถ้า Backend สั่งว่าให้ยิง event:purchase ( .cart.should_track_purchase )
      // https://app.asana.com/0/1199552129051613/1200637877668068/f
      if (response.data.cart && response.data.cart.should_track_purchase) {
        // console.log(response.data.cart.should_track_purchase);
        if (dispatch) {
          dispatch('try_to_track_purchase');
        }
      }

      if (rootGetters) {
        //
        // LnwPay
        //
        // ถ้ารองรับ LnwPay ให้โหลด LnwPoint มาด้วย
        if (rootGetters['order/canRedeemLnwPoint']) {
          if (rootState.user.userData) { // เฉพาะล็อคอินแล้ว
            if (!rootState.lnwpay.called.fetchLnwPayInfo) { // ถ้าโหลดแล้ว ไม่ต้องยิงไปโหลดอีก
              dispatch('lnwpay/fetchLnwPayInfo', null, {root: true});
            }
          }
        }

        //
        // LnwAccounts
        //
        const preferred_data = rootGetters['order/preferredUserResources'];
        if (rootState.user.userData) {
          dispatch('user/fetchUserResources', {preferred_data}, {root: true});
        }
      }

    }

  }
}

/**
 * สำหรับยิง event ด้วย GTAG แบบแยก payload ระหว่าง shop และ mall
 *
 * @param event
 * @param draft_payload
 * @param getters
 */
export const gtag_dual_groups = (event, draft_payload, getters) => {
  if (GTAG_TARGET_GROUP_ENABLE) {
    const shop_payload = Object.assign({}, draft_payload);
    shop_payload.send_to = 'shop';
    shop_payload.items = getters.signal_google_items_for_shop;
    gtag('event', event, shop_payload);
    if (getters.mall_gtag_ids.length > 0) {
      const mall_payload = Object.assign({}, draft_payload);
      mall_payload.send_to = 'mall';
      mall_payload.items = getters.signal_google_items_for_mall;
      gtag('event', event, mall_payload);
    }
  }
  else {
    // ยิง payload สำหรับ Shop ไปที่ gtag_ids ของ Shop
    const shop_payload = Object.assign({}, draft_payload);
    shop_payload.items = getters.signal_google_items_for_shop;
    shop_payload.send_to = getters.shop_gtag_ids;
    delete shop_payload.mall_transaction_id;
    gtag('event', event, shop_payload);
    // ยิง payload สำหรับ Mall ไปที่ gtag_ids ของ Mall
    if (getters.mall_gtag_ids.length > 0) {
      const mall_payload = Object.assign({}, draft_payload);
      mall_payload.items = getters.signal_google_items_for_mall;
      mall_payload.send_to = getters.mall_gtag_ids;
      mall_payload.transaction_id = draft_payload.mall_transaction_id;
      delete mall_payload.mall_transaction_id;
      gtag('event', event, mall_payload);
    }
  }
}

/**
 * เอา .gtag_ids และ .analytics_ids จาก backend มารวมกัน เพื่อไปใช้งานต่อ
 * @param getters
 * @param key
 * @returns {*[]}
 */
export const get_gtag_ids_list = (getters, key) => {
  const gtag_ids = [];
  if (getters[key]) {
    if (Array.isArray(getters[key].analytics_ids)) {
      for (const id of getters[key].analytics_ids) {
        gtag_ids.push(id);
      }
    }
    if (Array.isArray(getters[key].gtag_ids)) {
      for (const id of getters[key].gtag_ids) {
        gtag_ids.push(id);
      }
    }
  }
  return gtag_ids;
}