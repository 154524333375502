import Vue from 'vue'
import VueRouter from 'vue-router'
import SiteUrl from "@/libraries/SiteUrl.js";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter() {
      window.location.href = SiteUrl.account('manage');
    },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   route level code-splitting
  //   this generates a separate chunk (about.[hash].js) for this route
  //   which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/order/:web_name',
    name: 'order_list',
    component: () => import('../views/MyOrders.vue'),
  },
  {
    path: '/(inform_payment|informpayment)/:web_name',
    name: 'inform_payment',
    component: () => import('../views/InformPayment.vue'),
  },
  {
    path: '/:type(cart|order)/:web_name/:order_id',
    name: 'order',
    component: () => import('../views/Order.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// page-loading มันอยู่ที่ใน index.html ที่ไว้แสดงเวลาเข้ามาที่เว็บไปก่อน
router.afterEach(() => {
  let page_load = window.document.getElementById("page-loading");
  if (page_load) {
    page_load.remove();
  }
})

export default router